<script setup>
import { useStore } from "vuex";
import { usePageStore } from "~/pinia/platform/page.ts";

const config = useRuntimeConfig();
const store = useStore();
const pageStore = usePageStore();

const isStaging =
	store.state.host.split("telenor.no")[0].indexOf("staging") > -1 || config.public.devTargetEnv === "staging";
const isTest = store.state.host.split("telenor.no")[0].indexOf("test") > -1 || config.public.devTargetEnv === "test";

const showGizmoLink = computed(() => {
	return (
		((!config.public.previewMode && process.env.NODE_ENV !== "production") || isStaging || isTest) &&
		!pageStore.page?.metadata?.hideGizmo
	);
});

const pageLink = computed(() => {
	const gizmoUrl = isStaging
		? "https://cms.telenor.no/portal/telenor.no"
		: "https://test-cms.telenor.no/portal/telenor.no";
	const pageLink = pageStore.page.publishUrl || "";
	return gizmoUrl + pageLink + `?id=${pageStore.page.id}`;
});
</script>

<template>
	<div
		v-if="showGizmoLink"
		class="gizmo-link"
	>
		<a
			:href="pageLink"
			target="_blank"
		>
			<img
				src="./gizmo.png"
				alt="Edit page in Gizmo"
			/>
		</a>
	</div>
</template>

<style lang="scss" scoped>
.gizmo-link {
	a {
		position: relative;
		display: block;
		color: $color-neutrals-black;
		border-radius: 50%;
		background: $color-cta-default;
		box-shadow: 0 0 15px rgb(0 0 0 / 30%);
		transition:
			box-shadow 0.2s linear,
			background 0.2s linear;
		width: 80px;
		height: 80px;
		text-align: center;

		&:hover {
			background: $color-cta-hover;
			box-shadow: 0 0 15px rgb(0 0 0 / 50%);
		}

		img {
			position: relative;
			width: 70px;
			height: auto;
			top: 50%;
			margin-top: -50%;
			display: inline-block;
		}
	}
}
</style>
